module.exports = [{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"2395955140","enableOnDevMode":true},
    },{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://a1589a8badfd4598b3e3d66f97b52b41@sentry.io/1499728","environment":"production","enabled":true},
    },{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-styletron/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"st-"},
    },{
      plugin: require('/srv/thanx-signup-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
